

body {
  margin: 0;
  font-family: "Poppins",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: #fff;
  overflow-y: scroll;
  background: #fff;
  font-size: 16px;
  

}

h1 ,.h1{
  color:#fff;
}

.claim-box{
  display: block;
  width: 100% !important;
  border-radius: 0px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 20px !important;
  padding-top: 10px;
}
.neter{
  margin-right:10px!important;
  border-color: red;
  background: transparent;
  color: red;
}
.ts-labelb{
  margin-top: 0!important;
}
.alignWith{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}
.btnClose{
  background-color: transparent;
    border-color: transparent;
    font-size: x-large;
    padding: 0 20px 0 70px;
}
.typoinfo2{
  background:#030239!important;
  color:#fff!important;
  font-family: "Poppins",sans-serif!important;
  padding-top: 25px!important;
  font-size: 12px!important;
 padding-left:20px!important;
 padding-right:20px!important;
}
.typoinfo{
  background:#000!important;
  color:#fff!important;
  font-family: "Poppins",sans-serif!important;
  padding: 20px!important;
  font-size: 12px!important;

}
.titleDash{
  color:#9f9fa0;
  padding:15px 0;
}
.btn-dash{
  padding: 8px 20px!important;
}
.btn-dash h3{
  font-size:16px!important;
  padding:5px 0;
}
/*****table*******/
.MuiTable-root {
  color: #fff;
  background: #27283A;
}
.MuiToolbar-root {
  color: #fff;
}
.MTablePaginationInner-root-8 {
  color: #fff!important;
}
.MuiSelect-icon {
  color: #fff!important;
}
.MuiIconButton-root.Mui-disabled {
  color: #fff!important;
}
.MuiTableCell-root{
  border-bottom: 0!important;
}
.card{
  max-width: 785px;
  margin-left: auto;
  margin-right: auto;
  min-width: 256px;
  border-radius: 40px;
  background: #0D3885;
  border-radius: 28px;
}
.centerBlo{
  display: flex;
    align-items: stretch;
    margin-bottom: 35px;
    flex-direction: column;
    justify-content: space-between;
}
.centerBlo2{
  padding: 5px 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.centerBloc3{
  display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    margin-bottom: 50px;
}
.alignDiv{
  display: flex;
    align-items: center;
    justify-content: center;
}
.div1{
  background: #fff;
  padding: 15px 10px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left:15px;
  margin-right: 15px;
}
/*.liquidity-value{
  width: 280px;
}*/
button{
 
    color: #fff;
    background: #0D3885;
    border-radius: 50px;
    border: 1px solid #fff;
    padding:0 20px;
    min-width: 110px;
   /* cursor: url('./assets/curs2.png') , auto!important;*/
    cursor:pointer;
    

}
.posBtn{
   padding: 5px 0 25px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btnd{
  
  font-weight: 600;
  font-size: 28px;
  margin-top: 20px;
  min-width: 80%;
  color: #fff;
  background: #0D3885;
  border-radius: 50px;
  border: 1px solid #fff;
  padding: 0 20px;
  min-width: 60%;
  /*cursor: url('./assets/curs2.png') , auto!important;*/
  cursor: pointer;
}
/*.centerBlo .ts-value{
  min-width: 165px;
}*/
.RangeS{
  border-bottom: none!important;
}
.modalInp{
    background: #fff;
    border-radius: 15px;
    margin:30px 15px;
    padding: 24px;
    color: #0D3885;
    
}
.modalInp2{
  border-radius: 15px;
  margin:5px 15px;
  padding: 5px;
  color: #f5f5f5;
  font-size: 14px;
  
}
.modalInp input{
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 500;
  /*background: #0D3885;*/
  background: #0D3885;
  background-image: none;
  align-items: center;
  border-bottom: 1px solid #0D3885;
  text-align: center;
  /*cursor: url('./assets/curs2.png') , auto!important;*/
  cursor: pointer;
}
.modalInp input:before ,.css-1tcs2i1-MuiInputBase-root-MuiInput-root:before{
  border-bottom: 2px solid transparent!important;
}
.modalInp input:hover:not(.Mui-disabled):before{
  border-bottom: 2px solid transparent!important;
}
.posiCoin{
  display: grid;
    align-items: end;
   /* grid-template-columns: 60% 13% 27%;*/
   grid-template-columns: 70% 20% 11%;
    grid-column-gap: 5px;
}
.posiCoin22{
  display: grid;
    align-items: center;
   /* grid-template-columns: 60% 13% 27%;*/
   grid-template-columns: 65% 20% 10%;
    grid-column-gap: 5px;
}
/*.labelI input{
  background: transparent!important;
  border-bottom: 1px solid transparent!important;
}*/
.AligInfo{
  display: flex;
  flex-direction: row;
  gap:25px;
}

.languages-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
  padding:10px!important;
  cursor: pointer;
  list-style: none;
}
/*input[type=range].range-slider::-webkit-slider-thumb {
  background: url(./assets/curs2.png)!important;
  width:30px!important;
  height:30px!important;
  background-size: cover!important;
  margin-top:-12px!important;
}*/
input[type=range].range-slider::-webkit-slider-thumb {
  background-color:#0D3885!important;
}
.styI{
  color: #F77A00!important;
  border-radius: 50px;
  border: 1px solid #F77A00!important;
  padding: 0 20px;
  min-width: 110px;
  cursor: pointer;
  animation: clignote 3s linear infinite;
}

@keyframes clignote {  
  50% { opacity: 0.5; }
}
.AlignEs{
  display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
}
.inputEX,.inputEx.modalInp input{
  color:#0D3885!important;
  font-weight: bold!important;
}
.centerBloc3 .ts-label , .centerBloc3 .ts-value{
  color:#fff!important;
}
.flouLogo{
  filter: blur(1rem);
}