@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,600&display=swap');
#root {
  font-family: "Poppins",sans-serif;
}

a {
  color: #848e9c;
  text-decoration: none;
}
button, button:hover {transition:all 0.3s ease;}
button:focus {outline:0;}

/*-------- Header -----------*/
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  /*position: fixed;*/
  left: 0px;
  top: 0px;
  right: 20px;
  z-index: 11;
  max-height: 80px;
  margin-bottom: 25px;
  padding: 15px 0;
}

.logo-mobile {
  display: none;
}

#basic-button {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #1e194c;
  color: #fff;
  border: none;
  margin-left: 15px;
}

#basic-button svg {
  font-size: 2rem;
}
#basic-menu li {
  font-family: "Poppins",sans-serif;
  font-size: 13px;
  font-weight: 300;
}
nav .drop-container,
nav .drop-container button,
nav .drop-container button:hover {
  border: none !important;
  box-shadow: none;
  font-family: "Poppins",sans-serif;
}

nav .drop-container {
  background-color: #0D3885;
  border-radius: 50px;
}

nav .drop-container button {
  color: #fff;
  font-size: 15px;
  text-transform: none;
  font-weight: 300;
  padding: 5px 10px;
  background-color: #0D3885;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  
}

nav .user-btn {
  background-color: #282534;
  border: none;
  border-radius: 0 50px 50px 0;
}

nav .user-btn:hover {
  background-color: #27283a;
}

nav .user-btn svg {
  font-size: 1.3rem;
}

#poper-container .MuiPaper-rounded {
  background: #16171f;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #282534;
  position: relative;
  top: 10px;
  right: 38px;
}
#poper-container ul {
  padding: 0;
}
#poper-container .poper-header {
  position: relative;
}
#poper-container .poper-header::before {
  content: "";
  position: absolute;
  width: calc(100% - 2em);
  margin: auto;
  border-bottom: 1.5px solid #20212c;
  bottom: -2px;
}

#poper-container .poper-body {
  display: grid;
  align-items: center;
  grid-template-columns: 0.7fr 2fr 0.2fr 0.2fr;
  padding: 0 1em;
  grid-gap: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #f5f5f5;
}

.documentation,
.documentation:visited,
.documentation:active {
  color: inherit;
}

#poper-container .poper-body .poper-icon {
  width: 21px;
  fill: #bcc8d4;
  cursor: pointer;
  margin-top: -20px;
}

#poper-container .MuiPaper-rounded h4 {
  font-size: 16px;
  font-weight: 500;
  color: #bcbcbc;
  margin: 12px 0;
}

#poper-container .MuiPaper-rounded .logout {
  color: #f5f5f5;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  letter-spacing: 0.5px;
}

.logout {
  cursor: pointer;
}

#poper-container .MuiPaper-rounded #Layer_1 {
  width: 50px;
}

#poper-container .MuiPaper-rounded .logout svg {
  fill: #bcbcbc;
}

/*-------- End Header -----------*/

/*------------ Card --------*/

.main-content {
  min-height: calc(100vh - 300px);
}

#ts-card {
  margin-top: 120px;
  max-width: 740px!important;
  border-radius: 30px;
}
.card-container {
  background-color: #e4ebf6;
  max-width: 740px;
  margin: auto;
  border-radius: 30px;
  padding: 8px;
}

.card-title {
  display: block;
  padding: 15px 40px 10px;
  color:#0D3885;
  font-weight: 500;
  font-size: 26px;
  padding:40px 0;
  text-align: center;
}

.card-container .cardContent {

  background: #16171f;
  border-radius: 30px 30px 0 0;
  padding: 20px 35px;
  color: #fff;
  position: relative;

}

.card-container .cardContent::before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: calc(100% - 70px);
  margin: auto;
  border-bottom: 1.5px solid #20212c;
  bottom: 0px;
}

.card-container .cardContentBox {

  background: #16171f;
  border-radius: 0px 0px 30px 30px;
  padding: 10px 21px 25px 51px;
  color: #fff;

}
button:disabled{
  border: 1px solid transparent;
  background-color: #aba6a6;
  color: #000;
  pointer-events: none;
  opacity: 0.6;
}
.ts-btn-s2:disabled{
  pointer-events: none;
}
.card-container .cardContentBox .ts-btn-s,
.card-container .cardContentBox .ts-btn-s2 {
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50px;
  padding: 5px 20px;
  font-family: "Poppins",sans-serif;;
  font-weight: 500;
}

.card-container .cardContentBox .ts-btn-s {
  background-color: #c7f7ea;
  color: #159570;
}
.card-container .cardContentBox .ts-btn-s:hover {
  color: #c7f7ea;
  background-color: #159570;
}

.card-container .cardContentBox .ts-btn-s2 {
  background-color: #27283A;
  color: #fff;
}

.card-container .cardContentBox .ts-btn-s2:hover {
  color: #282534;
  background-color: #fff;
}

.card-container .cardContentBox .col1 {
  flex: 1 1 8em;
}

.card-container .cardContentBox .col2 {
  flex: 1 1 2em;
}

.card-container .cardContentBox .col3 {
  display: grid;
  justify-content: end;
  align-content: start;
}

.card-container .cardContent h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.liquidity-column {
  flex: 1 1 2em;
}
.card-container .cardContent .liquidity-label {
  color: #9f9fa0;
  font-size: 13px;
  font-weight: 500;
  display: block;
}
.card-container .cardContent a {
  float: left;
  margin-top: -2px;
}
.balance
{
  margin-bottom: 15px;
}
/*.balance .staked .liquidity-value::after
{
  content: 'LP-CAKE';
  font-size: 12px;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
}*/
.balance .icon-value
{
  padding-left: 50px;
  background-repeat: no-repeat;
  background-size: 42px;
}
.balance .icon-value.wallet
{
  background-image: url(../src/assets/wallet-gris.png);
}

.balance .icon-value.staked
{
  background-image: url(../src/assets/safe-deposit.png);
}

.exchange-block {
  width: 208px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  grid-gap: 8px;
  box-sizing: border-box;
  margin-bottom: 15px;
  position: relative;
  bottom: 2px;
}
.exchange-block p 
{
  margin-bottom: 0;
}
.exchange-btn
{
  display: block;
margin-bottom: 15px;
float: none !important;

}

.btn-large 
{
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50px;
  padding: 8px 30px;
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  color: #ccc;
  background: #27283A;
  width: 100%;
}
.btn-large:hover
{
  background: #fff;
  color: #27283A;
}

.btns-block a
{
  font-size: 12px;
  margin-right: 25px;
  color: #848e9c ;
  
}
.btns-block a:hover
{

  color: #159570;
}


.usdt,
.trs {
  padding-left: 22px;
  position: relative;
}

.usdt::before,
.trs::before {
  content: "";
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  position: absolute;
  left: 0;
}

.usdt::before {
  background-image: url("./assets/busd.png");
}
.trs::before {
  background-image: url("./assets/trs.png");
}
.modal-coin {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 24px;
  background-image: url("./assets/trs.png");
  padding-top: 5px;
 
}

.ts-label {
  font-size: 16px;
  color: #0D3885;
  margin-bottom: 0px;
}
.ts-label svg {
 
  fill: #fff;
  position: relative;
  top: 3px;
  font-size: 1rem;
  width:1.5em;
}
.ts-value {
  
  color: #0D3885;
  margin-top: 0px;
  margin-bottom: 12px;
}
.row-value
{
  display: grid;
/*grid-template-columns: auto 1fr;*/
}
.trs, .usdt
{
  padding-left: 25px;
  font-size: 12px;
}
.ts-value small {
  font-size: 12px;
}

.card-container .cardContentBox .col1 .ts-value {
  color: #159570;
}
/*.footer-row
{
  width: 100%;
  align-items: first baseline;
  gap: 20px;
  justify-content: space-between;
}
*/
.footer-row {
  justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 95px;
}

/*---------End Card -------------*/

/*---------Buttons TS-----------*/

/*.btn-form {
  display: block;
  background-color: #e4ebf6;
  margin: 0.5em auto;
  width: 100%;
  max-width: 256px;
  max-width: 700px;
  color: #000207;
  font-size: 23px;
  font-weight: 400;
  border-radius: 50px;
  border: none;
  padding: 15px;
  cursor: pointer;
  font-family: "Poppins",sans-serif;
  margin-top: 20px;
  transition: all 0.3s ease;
}*/
/*
.btn-form:disabled {
  display: block;
  background-color: #f0f2f5;
  margin: 0.5em auto;
  width: 100%;
  max-width: 256px;
  max-width: 700px;
  color: #a4abbe;
  font-size: 23px;
  font-weight: 400;
  border-radius: 50px;
  border: none;
  padding: 15px;
  font-family: "Poppins",sans-serif;
  margin-top: 20px;
  transition: all 0.3s ease;
  pointer-events: none;
}
*/
/*
.btn-form:hover {
  background-color: #27283A;
  color: #fff;
  transition: all 0.3s ease;
}
*/
.max-btn {
  border: none;
  padding: 0px 15px;
  background: #1e194c;
  color: #0D3885;
  font-family: "Poppins",sans-serif;  
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  border-radius: 50px;
  cursor: pointer;
}

/*------Modal---------*/

#add-modal .MuiBox-root {
  padding: 20px 5px;
  width: 600px;
  background-color: #0D3885;
  color: #fff;
  border-radius: 30px;
}
#add-modal .MuiBox-root span{
  color: #fff;
}

.cercleS{
  width:25px!important;
  height:25px!important;
}
#add-modal #modal-title {
  padding: 5px 20px;
  font-size: 23px;
}
#add-modal .btn-form{
  margin-top: 15px;
}

#add-modal #modal-title-withdraw {
  padding: 5px 20px;
  font-size: 23px;

}

#add-modal #modal-description {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}
#add-modal #modal-description span {
  color: #0D3885;
  font-size: 15px;
  
}

#add-modal .modal-input {
  display: grid;
  background: #030239;
  border-radius: 15px;
  margin: 10px 0 30px 0;
  padding: 5px 24px;
  color: #f5f5f5;
  grid-gap: 15px;
  grid-template-columns: 1.3fr 1fr 0.5fr;
  align-items: baseline;
}
#add-modal .modal-input input::placeholder{
  color:#fff!important;
}

#add-modal .modal-input input {
  color: #f5f5f5;
  font-size: 23px;
  font-weight: 500;
  background: none;
  background-image: none;
  align-items: center;
  border-bottom: 1px solid #fff;
}

#add-modal .modal-input .modal-coin {
  font-size: 16px;
}

.box-container {
  background: #0D3885;
  border-radius: 25px;
  padding: 30px 25px;
}

.footer {
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: 300;
  position: relative;
  margin-top: 60px;
}

.footer svg {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer a,
a:visited,
a:active {
  color: inherit;
}

.tv-icon {
  width: 55px;
}

@media only screen and (min-width: 601px) {
  nav .user-btn svg {
    position: relative;
    right: 2px;
  }
}
@media only screen and (min-width: 768px) {
  
  .dN-desktop{
    display:none;
  }
}
/*------Mobile Version -----------*/
@media only screen and (max-width: 767px) {
  #add-modal #modal-title-withdraw {
  
    font-size: 18px;
  }
  .posiCoin22 {
    grid-template-columns: 43% 28% 10%;
  }
  .ZoomD {
    zoom:.7;
  }
  /*.zoomM{
    zoom: .75;
    margin: 5px!important; 
  }*/
  .modalInp2 {
   
    padding: 0;
  }
  .modalInp2 h5{
      font-size: 1rem;
  }
  .div1 {
    padding: 15px 5px;
    margin-left:5px;
    margin-right: 5px;
  }
  .minN{
    zoom:.8;
  }
  .dN-mobile{
    display:none;
  }
  .liquidity-value ,.ts-value,.ts-value small{
    font-size: 14px!important;
  }
  
  label ,.modal-coin.small{
    font-size: 14px!important;
  }
  
  .modalInp {
    margin: 20px 5px;
    padding: 15px 5px;
  }
  .footer p{
    font-size: 14px;
  }
  .posiCoin {
   
    grid-template-columns: 50% 24% auto!important;
  }
  .AligInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between!important;
   
  }
  .AlignEs {
    display: grid!important;
    grid-template-columns: 50% 24% auto!important;
  }
  .centerBloc3 {
    
    flex-direction: column!important;
    align-items: left!important;
    margin-bottom: 50px;
    gap: 30px!important;
    padding:0 10%;
  }
  .ts-label {
    font-size: 14px;
  }
  .trs, .usdt {
    
    font-size: 14px;
  }
  .w280{
    width:280px;
  }
  /*.w56{
    min-width: 56%;
  }*/
  .div1 {
    align-items: center;
  }
  .centerBlo {
    align-items: center;
    display: grid;
    row-gap: 15px;
  }

  .nav {
    right: 10px;
    position: relative;
    /*grid-gap: 10px;*/
    margin-top: 5px;
    margin-bottom: 0;
  }

  .logo-mobile {
    display: block;
  }
  .logo-desktop {
    display: none;
  }
  #root {
    padding: 10px;
  }
  .makeStyles-card-1 {
    margin-top: 30px;
  }

  nav .drop-container .user-btn {
    padding: 7px 7px 7px 0px;
  }

  nav .drop-container .MuiButton-sizeLarge nav .user-btn {
    padding-right: 0;
  }

  #basic-button {
    margin-left: 10px;
  }
  .btn-large 
  {
    margin-bottom: 10px;
  }

  #ts-card {
    margin-top: 40px;
    
  }
  .card-title {
    padding: 12px 20px 8px 20px;
    font-size: 23px;
  }

  .card-container .cardContent {
  
    padding: 20px 10px;
  }
  .card-container .cardContent .liquidity-label {
    max-width: 100%;
    padding-top: 0px;
    margin-top: -2px;
  }
  .card-container .cardContent::before {
    width: 100%;
  }
 

  .exchange-block {
    font-size: 10px;
    grid-gap: 8%;
    padding: 0;
  }
  
  
  .usdt::before,
  .trs::before {
    background-size: 20px;
  }

  .card-container .cardContentBox .col1 {
    flex: 1 1 3em;
  }
  .card-container .cardContentBox .col3 {
    justify-content: start;
  }
  .footer-row
  {
    display: flex;
    flex-direction: row;
    gap: 35px;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;
  }
  .neter{
    font-size: 12px!important;
    margin-bottom: 5px!important;
  }

  #poper-container .MuiPaper-rounded {
    right: 0;
  }

  #add-modal .MuiBox-root {
    margin: 10px;
    padding:10px 0;
  }
  .box-container {
    padding: 5px 12px;
  }

  #add-modal .modal-input {
    padding: 5px 12px;
  }
  #add-modal .modal-input .modal-coin {
    font-size: 10px;
    background-size: 20px;
    padding-left: 27px;
  }
  .topcontent {
    margin-bottom: 20px;
  }
}
.css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  text-align: center;
  margin-top: 16px;
  color: white !important;
}

.topcontent {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}
.centerTex{
  text-align: center;
}
